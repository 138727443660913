import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiListItem: {
      gutters: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#000 !important",
        fontFamily: "'Work Sans', sans-serif !important",
        fontWeight: "300",
        fontSize: "14px !important",
        lineHeight: "14px",
        minHeight: "13px !important",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        padding: "15px",
      },
      paperFullScreen: { padding: "0px", borderRadius: "0px !important" },
    },
    MuiToolbar: {
      regular: {
        minHeight: "48px !important",
      },
      gutters: {
        padding: "0 !important",
      },
    },
    MuiSelect: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        paddingLeft: "15px",
        borderRadius: "18px",
      },
    },
    MuiInput: {
      root: {
        borderRadius: "10px",
      },
      underline: {
        "&:hover": {
          borderBottom: "none",
        },
        "&::before": {
          borderBottom: "none",
          display: "none",
        },
        "&::after": {
          borderBottom: "none",
        },
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        "&.Mui-expanded": {
          backgroundColor: "transparent",
          boxShadow: "none",
          margin: "0",
        },
        "&:before": {
          display: "none",
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: "0",
        // backgroundColor: "#000",
        padding: "18px 11px",
        "&.Mui-expanded": {
          margin: "0",
        },
      },
      root: {
        padding: "0",
        "&.Mui-expanded": {
          margin: "0",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        // backgroundColor: "#000",
        // color: "#45b897",
        padding: "8px 16px 16px",
        "@media (max-width:767px)": {
          padding: "10px",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#1f1e22 !important",
        background: "#fff !important",
      },
      input: {
        height: "2.1876em !important",
        // "@media (max-width:767px)": {
        //   height: "18px !important",
        // },
      },
    },
    MuiTableContainer: {
      root: {
        height: "100px",
      },
    },
    MuiTableCell: {
      sizeSmall: {
        "@media (max-width:768px)": {
          padding: "6px 0px 6px 10px",
        },
      },
      root: {
        borderBottom: " none",
      },
      body: {
        color: "#4298a8",
      },
    },
    MuiFormGroup: {
      root: {
        display: "flex",
        flexWrap: "inherit",
        flexDirection: "row",
        justifyContent: "space-around",
      },
    },
    MuiFormControlLabel: {
      root: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        marginLeft: "1px",
        marginRight: "16px",
        verticalAlign: "middle",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "0px",
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "rgba(255, 255, 255, 0.54)",
        padding: "8px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "50%",
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: "300 !important",
        fontSize: "14px !important",
      },
      outlinedPrimary: {
        color: "#000",
        fontSize: "16px",
        fontWeight: "600",
        border: "0",
        minWidth: "100px",
        background: "#45b897",
        borderRadius: "50px",
        "&:hover": {
          backgroundColor: "#6442F4",
          border: "1px solid #6442F4",
          color: "#fff",
        },
      },
      // containedPrimary: {
      //   backgroundColor: "#3C3C3C",
      //   "&:hover": {
      //     backgroundColor: "rgb(78 77 77)",
      //   },
      // },
      root: {
        minWidth: "auto",
      },
      textPrimary: {
        color: "#BFBFBF",
        fontWeight: 500,
        fontSize: "14px !important",
        lineHeight: "17px",
      },
      containedSizeSmall: {
        padding: "4px 10px",
        fontSize: "0.8125rem",
      },
      contained: {
        primary: {
          color: "#fff",
          boxShadow:
            "0 0 1px 0 rgb(0 0 0 / 70%), 0 2px 2px -2px rgb(0 0 0 / 50%)",
          backgroundColor: "#00D0AC",
        },
        secondary: {
          color: "#fff",
          backgroundColor: "#000000",
        },
      },
      outlinedSecondary: {
        color: "#000",
        backgroundColor: "#00D0AC",
        padding: "30px",
        "&:hover": {
          backgroundColor: "#6442F4",
          color: "#fff",
        },
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiPaper: {
      root: {
        color: "#e6e5e8",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#ffffff",
        borderRadius: "10px !important",
      },
      rounded: {
        borderRadius: "20px",
        "@media (max-width:767px)": {
          borderRadius: "5px",
        },
      },
    },

    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "rgb(200 200 200)",
        borderWidth: 1.8,
      },
      inputMultiline: { height: "auto !important", },
      root: {
        position: "relative",
        borderRadius: "10px",
        background: "#fff !important",
      },
      inputAdornedEnd: {
        "@media (max-width:767px)": {
          padding: "6px 8px",
          fontSize: "10px",
        },
      },
      inputAdornedStart: {
        paddingLeft: 14,
      },
      root: {
        position: "relative",
        borderRadius: "13px",
        "@media (max-width:767px)": {
          // borderRadius: "5px",
          // height: "35px",
        },
      },
      input: {
        padding: "11px 14px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "none",
        },
        "@media (max-width:767px)": {
          padding: " 6px 8px",
          fontSize: "14px",
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: "none",
        paddingBottom: "none",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiTypography: {
        colorPrimary: {
          color: "#fff",
        },
        colorSecondary: {
          color: "#222",
        }
      },
      MuiInputBase: {
        root: {
          backgroundColor: " #fff !important",
        },
        input: {
          height: "2.1876em !important",
          "&::placeholder": {
            opacity: 1,
            color: "#a1a1a1",
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "#5D29FF",
        light: "#00D0AC",
        dark: "#5D29FF",
        paper: "#ECECEC",
      },
      primary: {
        main: "#000080",
        blueMain: "#5D29FF",
      },
      secondary: {
        main: "#00D0AC",
      },
      white: {
        main: "#000",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        white: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    overrides: {
      MuiInputBase: {
        root: {
          backgroundColor: " #fff !important",
        },
        input: {
          height: "2.1876em !important",
          "&::placeholder": {
            opacity: 1,
            color: "#a1a1a1",
          },
        },
      },
      MuiTypography: {
        colorPrimary: {
          color: "#fff",
        }
      }
    },
    palette: {
      type: "dark",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "#5D29FF",
        light: "#00D0AC",
        dark: "#5D29FF",
        paper: "#ECECEC",
      },
      primary: {
        main: "#000080",
        blueMain: "#5D29FF",
      },
      secondary: {
        main: "#00D0AC",
      },
      white: {
        main: "#000",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        white: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
