import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  ListItem,
  List,
  IconButton,
  Hidden,
} from "@material-ui/core";
// import { FaTwitter } from "react-icons/ri";
import {
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTwitter,
} from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import { } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;
const useStyles = makeStyles((theme) => ({
  footerSection: {
    // background: "#f30065",
    background: "#000",
    position: "relative",
    padding: "50px 0px",
    backgroundPosition: " bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    // "&"
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      color: "#2f086a",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "0px",
      marginTop: "10px",
      fontWeight: "300",
      fontSize: "14px",
      lineHeight: "18px",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        fontWeight: "400",
        fontSize: "16px",
      },
    },
  },

  ListItem: {
    paddingLeft: "0px",
  },

  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },

  textFild: {
    position: "relative",

    "& .MuiOutlinedInput-input": {
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(200 255 0)",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "50px",
      height: "48px",
    },
    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#45b897",
      minWidth: "157px",
      fontSize: "14px",
      fontWeight: "300",
      color: "#000",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#45b897",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        position: "inherit",
        marginTop: "10px",
        fontSize: "22px",
      },
    },
  },
  footertext: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& a": {
      color: "#fff",
      fontWeight: 700,
      fontFamily: "'Roboto Flex', sans-serif",
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      alignItems: "left",
      textDecoration: "none",
      textTransform: "capitalize",
      marginBottom: "10px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "15px",
        fontWeight: 700,
      },
      "&:hover": {
        color: "#6442F4",
        textDecoration: "none",
      },
    },
  },
  socialmedia: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
    "& svg": {
      fontSize: "22px",
    },
    "& a": {
      color: "#6442F4",
      display: "contents",
    },
  },
  footernameBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  textcenter: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginBottom: "7px",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box
        className={classes.footerSection}
        style={{ backgroundImage: "url('./images/footerIMG.png')" }}
      >
        <Box
          style={{ margin: "20px 10px 0", position: "relative", zIndex: "2" }}
        >
          <Container maxWidth="lg">
            <Grid container justifyContent="space-around" spacing={1}>
              <Grid item xs={12} sm={8} md={7} align="left">
                <Box className={classes.footernameBox}>
                  <img alt="" src="images/logoNew.svg" />
                  {/* <Hidden xsDown>
                    <img alt="" src="images/logoIcon.png" style={{ width: "80px" }} />
                  </Hidden> */}
                </Box>
                <Box className={classes.socialmedia}>
                  <IconButton>
                    <a
                      target="_blank"
                      href="https://x.com/tradeleaf"
                      style={{ marginBottom: "0px", marginLeft: "-10px" }}
                    >
                      <FaTwitter />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      target="_blank"
                      href="https://t.me/tradeleaforg"
                      style={{ marginBottom: "0px", marginLeft: "-10px" }}
                    >
                      <FaTelegram />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      target="_blank"
                      href="https://t.me/trade_leaf"
                      style={{ marginBottom: "0px", marginLeft: "-10px" }}
                    >
                      <FaTelegram />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/tradeleaf.io/"
                      style={{ marginBottom: "0px", marginLeft: "-10px" }}
                    >
                      <FaInstagram />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/tradeleaf"
                      style={{ marginBottom: "0px" }}
                    >
                      <FaLinkedin />
                    </a>
                  </IconButton>
                  <IconButton>
                    <a
                      target="_blank"
                      href="https://tradeleaf.gitbook.io/trade-leaf"
                      style={{ marginBottom: "0px" }}
                    >
                      <FaGithub />
                    </a>
                  </IconButton>
                </Box>
                {/* <Box mt={7} className={classes.textcenter}>
                  <Typography variant="body2" style={{ color: "#fff" }}>
                    Don’t miss out international trade news and tips delivered
                    to your Inbox
                  </Typography>
                </Box>

                <Box mt={1} className={classes.textFild}>
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    variant="outlined"
                    placeholder="Your e-mail"
                  />
                  <Button style={{ fontWeight: "600" }}>SUBSCRIBE</Button>
                </Box> */}
              </Grid>
              <Grid item xs={12} sm={1} md={2} align="left"></Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Box className={classes.footertext}>
                  <ScrollLink
                    smooth={true}
                    duration={500}
                    // className={menuButton}
                    onClick={() => history.push("/?id=whitepaper")}
                    to="about"
                  >
                    About
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    duration={500}
                    style={{ scrollTop: "0" }}
                    onClick={() => history.push("/?id=whitepaper")}
                    // className={menuButton}
                    to="roadmap"
                  >
                    Roadmap
                  </ScrollLink>
                  <ScrollLink
                    smooth={true}
                    style={{ scrollTop: "0" }}
                    duration={500}
                    to="whitepaper"
                    onClick={() => history.push("/?id=whitepaper")}
                  >
                    Whitepaper
                  </ScrollLink>


                  <Hidden smUp>
                    <Box mt={2}>
                      <img
                        alt=""
                        src="images/logoNew.svg"
                        style={{ marginTop: "10px", width: "180px" }}
                      />{" "}
                    </Box>
                    <Box className={classes.socialmedia}>
                      <p>© Tradeleaf 2022</p>
                    </Box>
                  </Hidden>
                  {/**/}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
